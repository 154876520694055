<div class="left-nav nav-fullwidth" id="left-nav" [ngClass]="{'nav-fullwidth': showFullNavigation}">
  <nav class="left-nav-container" (clickOut)="showSubNavigation = []" mgrClickOutside>

    <!-- Users -->
    <mgr-navigation-item *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial,  USER_ROLES.FleetManager]"
      class="left-nav-item" routerLinkActive="active" [title]="'global-navigation-user_management' | translate"
      [icon]="'user-management'" (click)="toggleSubNavigation('users', $event)"
      [ngClass]="{'opened' : showSubNavigation['users']}">
      <li class="subnav-item" routerLinkActive="active">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.USER_MANAGEMENT}}/{{APP_ROUTES.REQUEST_APPROVAL}}">{{ 'global-navigation-request_approval' | translate }}</a>
      </li>
      <li class="subnav-item" routerLinkActive="active">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.USER_MANAGEMENT}}/{{APP_ROUTES.USER_LIST}}">{{ 'global-navigation-user_list' | translate }}</a>
      </li>
      <ng-container *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial]">
        <li class="subnav-item" routerLinkActive="active">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.USER_MANAGEMENT}}/{{APP_ROUTES.IMPORT_PROSPECT}}">{{ 'global-navigation-import_prospect' | translate }}</a>
        </li>
      </ng-container>
      <ng-container *restrictTo="[USER_ROLES.Administrator]">
        <li class="subnav-item" routerLinkActive="active" *areFeaturesActivated="[FEATURES.domainWhitelisting]">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.USER_MANAGEMENT}}/{{APP_ROUTES.WHITELIST_DOMAINS}}">{{ 'global-navigation-domain_whitelisting' | translate }}</a>
        </li>
      </ng-container>
      <li class="subnav-item" routerLinkActive="active">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.USER_MANAGEMENT}}/{{APP_ROUTES.GIVE_ACCESS}}">{{ 'global-navigation-my_ald_access' | translate }}</a>
      </li>
    </mgr-navigation-item>

    <!-- Fleet -->
    <mgr-navigation-item *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial,  USER_ROLES.FleetManager]"
      class="left-nav-item" [title]="'global-navigation-my_fleet' | translate" [icon]="'car'" routerLinkActive="active"
      (click)="toggleSubNavigation('fleet', $event)" [ngClass]="{'opened' : showSubNavigation['fleet']}">
      <li class="subnav-item" routerLinkActive="active">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.MY_FLEET}}/{{APP_ROUTES.CONTRACT_LIST}}">{{'global-navigation-contract_list' | translate}}</a>
      </li>
      <li class="subnav-item" routerLinkActive="active">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.MY_FLEET}}/{{APP_ROUTES.ORDER_LIST}}">{{'global-navigation-order_list' | translate}}</a>
      </li>
      <li class="subnav-item" routerLinkActive="active" *areFeaturesActivated="[FEATURES.maintenanceHistory]">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.MY_FLEET}}/{{APP_ROUTES.MAINTENANCE_HISTORY}}">{{'global-navigation-maintenance_history' | translate}}</a>
      </li>
      <li class="subnav-item" routerLinkActive="active" *areFeaturesActivated="[FEATURES.fuelCard]">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.MY_FLEET}}/{{APP_ROUTES.FUEL_CARD_HISTORY}}">{{'global-navigation-fuel_card_history' | translate}}</a>
      </li>
      <ng-container *restrictTo="[USER_ROLES.FleetManager]">
        <li class="subnav-item" routerLinkActive="active" *areFeaturesActivated="[FEATURES.salesforce]">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.MY_FLEET}}/{{APP_ROUTES.SALESFORCE_TICKETS}}">{{'global-navigation-salesforce_ticket_list' | translate}}</a>
        </li>
      </ng-container>
    </mgr-navigation-item>

    <!-- Documents -->
    <mgr-navigation-item *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial,  USER_ROLES.FleetManager]"
      class="left-nav-item" [title]="'global-navigation-documents' | translate" [icon]="'glovebox'"
      routerLinkActive="active" (click)="toggleSubNavigation('documents', $event)"
      [ngClass]="{'opened' : showSubNavigation['documents']}">
      <li class="subnav-item" routerLinkActive="active">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.DOCUMENTS}}/{{APP_ROUTES.DOCUMENT_LIST}}">{{'global-navigation-document_list' | translate}}
        </a>
      </li>
      <li class="subnav-item" routerLinkActive="active" *areFeaturesActivated="[FEATURES.sharedDocuments]">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.DOCUMENTS}}/{{APP_ROUTES.DOCUMENT_LIST_SHARED_BY_DRIVER}}">{{'global-navigation-document_shared_list' | translate}}
        </a>
      </li>
    </mgr-navigation-item>

    <!-- Reports -->
    <mgr-navigation-item *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial,  USER_ROLES.FleetManager]"
      class="left-nav-item" routerLinkActive="active" [title]="'global-navigation-my_reports' | translate"
      [icon]="'reports'" (click)="toggleSubNavigation('reports', $event)"
      [ngClass]="{'opened' : showSubNavigation['reports']}">
      <li class="subnav-item"  *areFeaturesActivated="[FEATURES.aldNetReportsWithoutToken]">
        <a class="subnav-link" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
          [gtmLabel]="'global-navigation-ald_net_reports' | translate" href="{{reportUrl}}"
          target="_blank">{{'global-navigation-ald_net_reports' | translate}}</a>
      </li>
      <li class="subnav-item" *areFeaturesActivated="[FEATURES.aldNetReportsWithToken]">
        <a (click)="openReportsWithToken()" class="subnav-link" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
          [gtmLabel]="'global-navigation-ald_net_reports' | translate">{{'global-navigation-ald_net_reports' | translate}}</a>
      </li>
      <li *areFeaturesActivated="[FEATURES.ifrsReports]" class="subnav-item">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.REPORTS}}">{{'global-navigation-ifrs16_reports' | translate}}</a>
      </li>
    </mgr-navigation-item>

    <!-- Quotation -->
    <ng-container *ngIf="isQuoterActivated">
      <mgr-navigation-item class="left-nav-item" routerLinkActive="active"
        [title]="'global-navigation-quotation' | translate" [icon]="'quotation'"
        (click)="toggleSubNavigation('quotation', $event)" [ngClass]="{'opened' : showSubNavigation['quotation']}"
        *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial,  USER_ROLES.FleetManager]">
        <li class="subnav-item" *ngIf="aldQuoteUrl">
          <a class="subnav-link" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
            [gtmLabel]="'global-navigation-ald_quote' | translate" (click)="setAldQuoteUrl()"
            target="_blank">{{'global-navigation-ald_quote' | translate}}</a>
        </li>
        <li class="subnav-item" *ngIf="aldQuoteV2Url">
          <a class="subnav-link" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
            [gtmLabel]="'global-navigation-ald_quote_front_office' | translate" href="{{aldQuoteV2Url}}"
            target="_blank">{{'global-navigation-ald_quote_front_office' | translate}}</a>
        </li>
        <li class="subnav-item" *ngIf="carSelectorUrl">
          <a class="subnav-link" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
            [gtmLabel]="'global-navigation-car_selector' | translate" (click)="openCarSelectorLink()"
            target="_blank">{{'global-navigation-car_selector' | translate}}</a>
        </li>
      </mgr-navigation-item>
    </ng-container>

    <!-- Notifications -->
    <mgr-navigation-item *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial,  USER_ROLES.FleetManager]"
      class="left-nav-item" routerLinkActive="active" [title]="'global-navigation-notifications' | translate"
      [icon]="'feed'" (click)="toggleSubNavigation('notification', $event)"
      [ngClass]="{'opened' : showSubNavigation['notification']}">
      <li class="subnav-item" routerLinkActive="active">
        <a class="subnav-link"
          routerLink="/{{APP_ROUTES.NOTIFICATIONS}}">{{'global-navigation-notification_list' | translate}}
        </a>
      </li>
    </mgr-navigation-item>

    <!-- Services -->
    <ng-container *restrictTo="[USER_ROLES.FleetManager]">
      <mgr-navigation-item class="left-nav-item" routerLinkActive="active"
        [title]="'global-navigation-services' | translate" [icon]="'headphones'"
        (click)="toggleSubNavigation('service', $event)" [ngClass]="{'opened' : showSubNavigation['service']}"
        *ngIf="smartCareUrl || isTrafficFinesV1Activated || isTrafficFinesV2Activated">
        <li class="subnav-item" routerLinkActive="active" *ngIf="smartCareUrl">
          <a class="subnav-link" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
            [gtmLabel]="'global-navigation-smart_care' | translate" href="{{smartCareUrl}}"
            target="_blank">{{'global-navigation-smart_care' | translate}}
          </a>
        </li>
        <li class="subnav-item" routerLinkActive="active"
          *ngIf="isTrafficFinesV1Activated || isTrafficFinesV2Activated">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.SERVICES}}/{{APP_ROUTES.TRAFFIC_FINES}}">{{'global-navigation-traffic_fines' | translate}}
          </a>
        </li>
      </mgr-navigation-item>
    </ng-container>

    <!-- Administration -->
    <ng-container *restrictTo="[USER_ROLES.Administrator]">
      <mgr-navigation-item class="left-nav-item" routerLinkActive="active"
        [title]="'global-navigation-administration' | translate" [icon]="'settings'"
        (click)="toggleSubNavigation('administration', $event)"
        [ngClass]="{'opened' : showSubNavigation['administration']}">
        <li class="subnav-item" routerLinkActive="active">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.ADMINISTRATION}}/{{APP_ROUTES.ADMINISTRATOR_LIST}}">{{'global-navigation-administrator_list' | translate}}</a>
        </li>
        <li class="subnav-item" routerLinkActive="active" *ngIf="isManageSupplierActivated">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.ADMINISTRATION}}/{{APP_ROUTES.SUPPLIER_LIST}}">{{'global-navigation-supplier_list' | translate}}</a>
        </li>
        <li class="subnav-item" routerLinkActive="active" *areFeaturesActivated="[FEATURES.translationManagement]">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.ADMINISTRATION}}/{{APP_ROUTES.TRANSLATION_LANDING_PAGE}}">{{'global-navigation-translation_management' | translate}}</a>
        </li>
        <li class="subnav-item" routerLinkActive="active" *ngIf="isPosManagementActivated">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.ADMINISTRATION}}/{{APP_ROUTES.POS_LISTING}}">{{'global-navigation-pos_management' | translate}}</a>
        </li>
        <li class="subnav-item" routerLinkActive="active" *areFeaturesActivated="[FEATURES.featureManagement]">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.ADMINISTRATION}}/{{APP_ROUTES.FEATURES_LANDING_PAGE}}">{{'global-navigation-feature_management' | translate}}</a>
        </li>
      </mgr-navigation-item>
    </ng-container>

    <!-- POS Managemnt - only commercial user -->
    <ng-container  *restrictTo="[USER_ROLES.Commercial]">
      <mgr-navigation-item *ngIf="isPosManagementActivated" class="left-nav-item" routerLinkActive="active"
        [title]="'global-navigation-administration' | translate" [icon]="'settings'"
        (click)="toggleSubNavigation('administration', $event)"
        [ngClass]="{'opened' : showSubNavigation['administration']}">
        <li class="subnav-item" routerLinkActive="active">
          <a class="subnav-link"
            routerLink="/{{APP_ROUTES.ADMINISTRATION}}/{{APP_ROUTES.POS_LISTING}}">{{'global-navigation-pos_management' | translate}}</a>
        </li>
      </mgr-navigation-item>
    </ng-container>
    
    <!-- ALD sat -->
    <ng-container *restrictTo="[USER_ROLES.Supplier]">
      <mgr-navigation-item class="left-nav-item" routerLinkActive="active"
        [title]="'global-navigation-ald_sat' | translate" [icon]="'box'"
        (click)="toggleSubNavigation('ald-sat', $event)" [ngClass]="{'opened' : showSubNavigation['ald-sat']}">
        <li class="subnav-item" routerLinkActive="active">
          <a class="subnav-link" routerLink="/{{APP_ROUTES.ALDSAT}}">{{'global-navigation-ald_sat' | translate}}</a>
        </li>
      </mgr-navigation-item>
    </ng-container>
    <!-- T&C -->
    <div *ngIf="showFullNavigation" class="left-nav-terms-and-conditions">
      <a href="{{getLinkToCookiesPolicy()}}" target="_blank" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
      [gtmLabel]="'global-navigation-cookies_policy' | translate"> {{ 'global-navigation-cookies_policy' | translate}}</a>
      |
      <a href="{{getLinkToTermsOfUse()}}" target="_blank" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
      [gtmLabel]="'global-navigation-terms_conditions' | translate"> {{ 'global-navigation-terms_conditions' | translate}}</a>
    </div>

    <!-- T&C closed nav and mobile  -->
    <mgr-navigation-item   class="left-nav-tandc-icon"  routerLinkActive="active"
    [icon]="'compliance'" (click)="toggleSubNavigation('terms_condition', $event)"
    [ngClass]="{'opened' : showSubNavigation['terms_condition'], 'nav-fullwidth': showFullNavigation}">
    <li class="subnav-item" routerLinkActive="active">
      <a class="subnav-link" href="{{getLinkToTermsOfUse()}}" target="_blank" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
      [gtmLabel]="'global-navigation-terms_conditions' | translate">{{ 'global-navigation-terms_conditions' | translate}}</a>
    </li>
    <li class="subnav-item" routerLinkActive="active">
      <a class="subnav-link" href="{{getLinkToCookiesPolicy()}}"  target="_blank" [gtmClickEvent]="GTM_EVENTS.clickNavigation"
      [gtmLabel]="'global-navigation-cookies_policy' | translate"> {{'global-navigation-cookies_policy' | translate}} </a>
    </li>
  </mgr-navigation-item>

    <span class="left-nav-version">3.60.1</span>

    <span class="left-nav-enlarge" [ngClass]="{'nav-fullwidth': showFullNavigation}"
      (click)="toggleFullNavigation()"></span>
  </nav>
</div>